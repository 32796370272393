import { StudiosType } from '../types/studios.type';
import { HTTPServices } from './base.service';

export class StudiosServices extends HTTPServices {
    private static BASE_ROUTE = 'studios/';

    static getAll() {
        return StudiosServices.get<StudiosType[]>(StudiosServices.BASE_ROUTE);
    }

    static add(data: StudiosType) {
        return StudiosServices.post<StudiosType>(StudiosServices.BASE_ROUTE, data);
    }

    static update(id: number, data: StudiosType) {
        return StudiosServices.put<StudiosType>(StudiosServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return StudiosServices.get<StudiosType>(StudiosServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return StudiosServices.delete(StudiosServices.BASE_ROUTE + id);
    }
}
