import React from 'react';
import { Autocomplete, TextField, IconButton, Modal, Fade, Box } from '@mui/material';
import { Search } from '@mui/icons-material';
import { OptionType } from '../../components/AppAutocompleteField';
import { ConfigService } from '../../services/config.service';
import { ExerciseType } from '../../types/exercise.type';
import { AppBox } from '../../components/AppBox';

type ExercisePickerProps = {
    exercise?: Partial<ExerciseType>
    options: OptionType[];
    label: string;
    onChange: (exerciseId: number) => void;
};

export function ExercisePicker({
    exercise,
    options,
    label,
    onChange
}: ExercisePickerProps) {
    const [preview, setPreview] = React.useState<string | null>(null);

    const handleOpenPreview = (videoUrl: string) => {
        setPreview(ConfigService.FILE_STORAGE_URL + videoUrl);
    };

    const handleClosePreview = () => {
        setPreview(null);
    };

    return (
        <AppBox display="flex">
            <Autocomplete
                style={{ flex: 1 }}
                value={exercise ? {
                    id: exercise?.id!!,
                    name: exercise?.name!!
                } : null as any}
                multiple={false}
                options={options}
                disableClearable
                getOptionLabel={(option: OptionType) => option.name}
                isOptionEqualToValue={(option: OptionType, value: OptionType | undefined) => {
                    if (!value) return false;
                    return option.id === value.id;
                }}
                onChange={(_, selectedOption) => {
                    if (selectedOption) {
                        onChange(selectedOption.id);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                    />
                )}
            />
            <IconButton
                color="primary"
                onClick={() => handleOpenPreview(exercise?.video!)}
                disabled={!exercise?.video}
            >
                <Search />
            </IconButton>

            {/* Video Preview Modal */}
            <Modal open={preview !== null} onClose={handleClosePreview} closeAfterTransition>
                <Fade in={preview !== null}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                        {preview && (
                            <video width="100%" controls loop autoPlay src={preview}>
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </AppBox>
    );
}
