import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PageNotFound } from './PageNotFound';
import { Menu } from './Menu';
import { TargetAreasList } from '../../targetareas/TargetAreasList';
import { EquipmentsList } from '../../equipments/EquipmentsList';
import { EditEquipment } from '../../equipments/EditEquipment';
import { AppBar, Toolbar, IconButton, Drawer, Typography } from '@mui/material';
import { EditTargetArea } from '../../targetareas/CreateTargetArea';
import { ConfirmationDialogProvider } from '../../../hooks/dialog.hook';
import { ExerciseTypesList } from '../../exercisetypes/ExerciseTypesList';
import { EditExerciseType } from '../../exercisetypes/EditExerciseType';
import { ExercisesList } from '../../exercises/ExercisesList';
import { EditExercise } from '../../exercises/EditExercise';
import { WorkoutsList } from '../../workouts/WorkoutsList';
import { EditWorkout } from '../../workouts/EditWorkout';
import { MovementsList } from '../../movements/MovementsList';
import { MovementEdit } from '../../movements/MovementEdit';
import { ClassEdit } from '../../classes/ClassEdit';
import { ClassesList } from '../../classes/ClassesList';
import { CLassExercisesEdit } from '../../classes/ClassExercisesEdit';
import { TrainersList } from '../../trainers/TrainersList';
import { EditTrainers } from '../../trainers/EditTrainers';
import { ConfigService } from '../../../services/config.service';
import { useSession } from '../../../hooks/auth.hook';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { ClassesPreview } from '../../classes/ClassesPreview';
import { StudioList } from '../../studios/StudiosList';
import { EditStudio } from '../../studios/EditStudio';
import { WarmupsList } from '../../warmups/WarmupsList';
import { WarmupEdit } from '../../warmups/WarmupEdit';


const routeConfig = [
    { path: '/', element: <Navigate to='/classes' /> },
    { path: '/classes', element: <ClassesList /> },
    { path: '/classes/create', element: <ClassEdit mode='create' /> },
    { path: '/classes/edit/:id', element: <ClassEdit mode='edit' /> },
    { path: '/classes/preview/:date/:time', element: <ClassesPreview /> },
    { path: '/classes/exercises/edit', element: <CLassExercisesEdit /> },
    { path: '/exercises', element: <ExercisesList /> },
    { path: '/exercises/create', element: <EditExercise mode='create' /> },
    { path: '/exercises/edit/:id', element: <EditExercise mode='edit' /> },
    { path: '/exercisetypes', element: <ExerciseTypesList /> },
    { path: '/exercisetypes/create', element: <EditExerciseType mode='create' /> },
    { path: '/exercisetypes/edit/:id', element: <EditExerciseType mode='edit' /> },
    { path: '/targetareas', element: <TargetAreasList /> },
    { path: '/targetareas/create', element: <EditTargetArea mode='create' /> },
    { path: '/targetareas/edit/:id', element: <EditTargetArea mode='edit' /> },
    { path: '/equipments', element: <EquipmentsList /> },
    { path: '/equipments/create', element: <EditEquipment mode='create' /> },
    { path: '/equipments/edit/:id', element: <EditEquipment mode='edit' /> },
    { path: '/workouts', element: <WorkoutsList /> },
    { path: '/workouts/create', element: <EditWorkout mode='create' /> },
    { path: '/workouts/edit/:id', element: <EditWorkout mode='edit' /> },
    { path: '/movements', element: <MovementsList /> },
    { path: '/movements/create', element: <MovementEdit mode='create' /> },
    { path: '/movements/edit/:id', element: <MovementEdit mode='edit' /> },
    { path: '/warmups', element: <WarmupsList /> },
    { path: '/warmups/create', element: <WarmupEdit mode='create' /> },
    { path: '/warmups/edit/:id', element: <WarmupEdit mode='edit' /> },
    { path: '/trainers', element: <TrainersList /> },
    { path: '/trainers/create', element: <EditTrainers mode='create' /> },
    { path: '/trainers/edit/:id', element: <EditTrainers mode='edit' /> },
    { path: '/studios', element: <StudioList /> },
    { path: '/studios/create', element: <EditStudio mode='create' /> },
    { path: '/studios/edit/:id', element: <EditStudio mode='edit' /> },

    { path: '*', element: <PageNotFound /> },
];

const drawerWidth = 240;

export function Workspace() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { user, logout } = useSession();

    React.useEffect(() => {
        ConfigService.loadConfig();
    }, []);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <BrowserRouter>
            <ConfirmationDialogProvider>
                <div className='app-layout'>
                    <AppBar
                        position='fixed'
                        sx={{
                            width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
                            ml: `${drawerOpen ? drawerWidth : 0}px`,
                            transition: 'width 0.3s',
                        }}
                    >
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <IconButton edge='start' color='inherit' aria-label='menu' onClick={toggleDrawer(true)}>
                                <MenuIcon />
                            </IconButton>
                            <img src="/images/logo.jpeg" alt="DoHIIT Logo" style={{ height: '60px', marginRight: 'auto' }} />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ flexGrow: 1 }}>
                                    Welcome, {user?.name || user?.email}
                                </Typography>
                                <IconButton color="error" aria-label='logout' onClick={() => logout()}>
                                    <LogoutIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        variant='persistent'
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                    >
                        <Menu />
                    </Drawer>
                    <div
                        className='app-main-content'
                        style={{
                            flexGrow: 1,
                            padding: '24px',
                            marginLeft: drawerOpen ? `${drawerWidth}px` : '0',
                            transition: 'margin 0.3s',
                        }}
                    >
                        <Toolbar /> {/* To push content below the AppBar */}
                        <Routes>
                            {routeConfig.map((route, index) => (
                                <Route key={index} path={route.path} element={route.element} />
                            ))}
                        </Routes>
                    </div>
                </div>
            </ConfirmationDialogProvider>
        </BrowserRouter>
    );
}
