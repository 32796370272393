import { Workspace } from './modules/workspace/components/Workspace';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoginComponent } from './modules/login/Login';
import { SnackbarProvider } from './hooks/snackbar.hook';
import { useSession } from './hooks/auth.hook';
import { AppLoader } from './components/AppLoader';
import './App.scss';

function App() {

  const { user, isLoading } = useSession();

  const AppTheme = createTheme({
    palette: {
      primary: {
        main: "#0C0C0C",
      },
      secondary: {
        main: "#3D3D3D",
      }
    }
  });

  return (
    <ThemeProvider theme={AppTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider>
          <div className="App">
            {user ? <Workspace /> : <LoginComponent />}
            <AppLoader isLoading={isLoading} />
          </div>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
