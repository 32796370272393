import { ImageRenderer } from '../../components/renderers/image.renderer';
import { TrainersServices } from '../../services/trainers.service';
import ListComponent from '../common/ListComponent';

export function TrainersList() {
    return (
        <ListComponent
            title='Trainers'
            getAll={TrainersServices.getAll}
            deleteById={TrainersServices.deleteById}
            columns={
                [
                    { field: 'profile_image', headerName: 'Image', flex: 0.2, renderCell: ImageRenderer },
                    { field: 'name', headerName: 'Name', flex: 1 },
                    {
                        field: 'user_studios',
                        headerName: 'Studios',
                        flex: 1,
                        valueGetter: (row: any) => row.map((value: any) => value.studio.name).join(', '),
                    }
                ]

            }

        />
    );
};