import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent from '../common/EditFormComponent';
import { StudiosType } from '../../types/studios.type';
import { StudiosServices } from '../../services/studios.service';

export function EditStudio({ mode }: CRUDType) {

    return (
        <EditFormComponent<StudiosType>
            title="Studio"
            mode={mode}
            backPath="/studios"
            service={{
                getById: StudiosServices.getById,
                add: StudiosServices.add,
                update: StudiosServices.update,
            }}
            formFields={[
                { name: 'name', component: AppTextField, props: { label: 'Name', rules: { required: 'Field is required' }, columns: 6 } },
                { name: 'code', component: AppTextField, props: { label: 'Code', columns: 2, disabled: true } },
                { name: 'address', component: AppTextField, props: { label: 'Address', columns: 12 } },
                { name: 'city', component: AppTextField, props: { label: 'City', columns: 6 } },
                { name: 'state', component: AppTextField, props: { label: 'State', columns: 3 } },
                { name: 'country', component: AppTextField, props: { label: 'Country', columns: 3 } },
            ]
            }
        />
    );

}