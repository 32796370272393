import { Controller } from 'react-hook-form';
import { RadioGroup, Radio, FormControlLabel, Typography, FormLabel, FormControl } from '@mui/material';
import { AppFieldProps } from '../types/components.type';
import { AppBox } from './AppBox';

export type AppRadioGroupFieldProps = AppFieldProps & {
    label?: string;
    options: { label: string; value: string | number }[];
};

export function AppRadioGroupField(props: AppRadioGroupFieldProps) {
    const { name, control,setValue, rules, label, options } = props;

    return (
        <AppBox>
            <Controller
                name={name as string}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => (
                    <FormControl component="fieldset">
                        {label && <FormLabel component="legend">{label}</FormLabel>}
                        <RadioGroup
                            {...field}
                            row
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                        >
                            {options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            ))}
                        </RadioGroup>
                        {fieldState.error && (
                            <Typography color="error">
                                {fieldState.error.message}
                            </Typography>
                        )}
                    </FormControl>
                )}
            />
        </AppBox>
    );
}
