import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import CategoryIcon from '@mui/icons-material/Category';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import StudioIcon from '@mui/icons-material/Home';
import WarmupIcon from '@mui/icons-material/LocalFireDepartment';
import { Link } from "react-router-dom";

const menuItems = [
    { text: 'Classes', icon: <CalendarIcon />, path: '/classes' },
    { text: 'Workouts', icon: <DirectionsRunIcon />, path: '/workouts' },
    { text: 'Warm Ups', icon: <WarmupIcon />, path: '/warmups' },
    { text: 'Movements', icon: <AutorenewIcon />, path: '/movements' },
    { text: 'Exercises', icon: <FitnessCenterIcon />, path: '/exercises' },
    { text: 'Exercise Types', icon: <CategoryIcon />, path: '/exercisetypes' },
    { text: 'Equipments', icon: <BuildIcon />, path: '/equipments' },
    { text: 'Target Areas', icon: <AccessibilityNewIcon />, path: '/targetareas' },
    { text: 'Trainers', icon: <PersonIcon />, path: '/trainers' },
    { text: 'Studios', icon: <StudioIcon />, path: '/studios' },
];

export function Menu() {
    return (
        <List>
            {menuItems.map((item, index) => (
                <React.Fragment key={item.text}>
                    <ListItem component={Link} to={item.path}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                    {index < menuItems.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </List>
    );
}
