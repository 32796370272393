import { Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { AppFieldProps } from '../types/components.type';
import { AppBox } from './AppBox';

export type AppTextFieldProps = AppFieldProps & TextFieldProps;

export function AppTextField(props: AppTextFieldProps) {
    const { name, control, setValue, rules, defaultValue = '', ...rest } = props;
    return (
        <AppBox>
            <Controller
                name={name as string}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => (
                    <TextField
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                        {...field}
                        {...rest}
                    />
                )}
            />
        </AppBox>
    );
}
