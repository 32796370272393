import { WarmupsServices } from '../../services/warmups.service';
import ListComponent from '../common/ListComponent';

export function WarmupsList() {
    return (
        <ListComponent
            title='Warm Ups'
            getAll={WarmupsServices.getAll}
            deleteById={WarmupsServices.deleteById}
            columns={
                [
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1
                    },
                    {
                        field: 'exercises_duration',
                        headerName: 'Exercises Duration',
                        flex: 0.5
                    },
                    {
                        field: 'exercises_count',
                        headerName: '# Exercises',
                        flex: 0.5
                    }
                ]
            }

        />
    );
};