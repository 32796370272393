import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { AppFieldProps } from '../types/components.type';
import { HttpResponse } from '../services/base.service';

export interface OptionType {
    id: number;
    name: string;
}

export type AppAutocompleteFieldProps = {
    /* The name of the property where it will be save the option id */
    valueProperty?: string;
    service: {
        /**
         * If you desire static options
         */
        options?: OptionType[];
        /**
         * If you want to load, for instance, from a endpoint
         * @returns 
         */
        load?: () => Promise<HttpResponse>
    };
    multiple?: boolean;
} & AppFieldProps

export function AppAutocompleteField(props: AppAutocompleteFieldProps) {
    const { name, control, setValue, label, rules, service, valueProperty = 'id', multiple = true } = props;

    const [options, setOptions] = React.useState<OptionType[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);


    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                if (service.load) {
                    const response = await service.load();
                    setOptions(response.data as OptionType[]);
                } else {
                    setOptions(service.options || []);
                }
            } catch (error) {
                console.error("Error fetching autocomplete options", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [service]);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {

                let selectedOptions: OptionType | OptionType[] | null = multiple ? [] : null;
                if (field.value !== undefined && field.value !== null) {
                    selectedOptions = Array.isArray(field.value)
                        ? options.filter(option => field.value.some((val: any) => val[valueProperty] === option.id))
                        : options.find(option => option.id === field.value) || null;
                }


                return (
                    <Autocomplete
                        {...field}
                        value={selectedOptions}
                        multiple={multiple}
                        options={options}
                        getOptionLabel={(option: OptionType) => option.name}
                        isOptionEqualToValue={(option: OptionType, value: OptionType) => option.id === value.id}
                        onChange={(_, data) => {
                            // Transform data to only include IDs in a object( if multiple) or just a primitive 
                            const transformedData = Array.isArray(data) ?
                                data.map((item: OptionType) => ({ [valueProperty]: item.id })) : data?.id;

                            field.onChange(transformedData);
                            props.onChange?.(transformedData);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                fullWidth
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />)
            }}
        />
    );
}
