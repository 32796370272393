import { StudiosServices } from '../../services/studios.service';
import ListComponent from '../common/ListComponent';

export function StudioList() {
    return (
        <ListComponent
            title='Studios'
            getAll={StudiosServices.getAll}
            deleteById={StudiosServices.deleteById}
            columns={
                [
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'code', headerName: 'Code', flex: 1 }
                ]
            }

        />
    );
};