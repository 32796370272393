import { AppTextField } from '../../components/AppTextField';
import { CRUDType } from '../../types/crud.type';
import EditFormComponent from '../common/EditFormComponent';
import { WarmupType } from '../../types/warmup.type';
import { ValidationUtils } from '../../utils/validations.utils';
import { WarmupsServices } from '../../services/warmups.service';
import { WarmupExercisesField } from './WarmUpExercisesField';

export function WarmupEdit({ mode }: CRUDType) {
    return (
        <EditFormComponent<WarmupType>
            title="Warm Up"
            mode={mode}
            backPath="/warmups"
            service={{
                getById: WarmupsServices.getById,
                add: WarmupsServices.add,
                update: WarmupsServices.update,
            }}
            formFields={[
                {
                    name: 'name',
                    component: AppTextField,
                    props: {
                        label: 'Name',
                        rules: {
                            required: 'Name is required'
                        },
                        columns: 8
                    }
                },
                {
                    name: 'exercises_duration',
                    component: AppTextField,
                    props: {
                        label: 'Exercises Duration (s)',
                        rules: {
                            validate: ValidationUtils.isInteger
                        },
                        columns: 2
                    }
                },
                {
                    name: 'exercises_count',
                    component: AppTextField,
                    props: {
                        label: 'Exercises Count',
                        rules: {
                            validate: ValidationUtils.isInteger
                        },
                        columns: 2
                    }
                },
                {
                    name: 'warmup_exercises',
                    component: WarmupExercisesField,
                },

                //TODO button to generate exercises, list with the exercises?
            ]}
        />
    );

}