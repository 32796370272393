import { WarmupType } from '../types/warmup.type';
import { HTTPServices } from './base.service';

export class WarmupsServices extends HTTPServices {
    private static BASE_ROUTE = 'warmups/';

    static getAll() {
        return WarmupsServices.get<WarmupType[]>(WarmupsServices.BASE_ROUTE);
    }

    static add(data: WarmupType) {
        return WarmupsServices.post<WarmupType>(WarmupsServices.BASE_ROUTE, data);
    }
    static update(id: number, data: WarmupType) {
        return WarmupsServices.put<WarmupType>(WarmupsServices.BASE_ROUTE + id, data);
    }

    static getById(id: number) {
        return WarmupsServices.get<WarmupType>(WarmupsServices.BASE_ROUTE + id);
    }

    static deleteById(id: number) {
        return WarmupsServices.delete<WarmupType[]>(WarmupsServices.BASE_ROUTE + id);
    }
}
