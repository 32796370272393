import React from 'react';
import { Container } from '@mui/material';
import { AppToolbar } from '../../components/AppToolbar';
import { AppButton } from '../../components/AppButton';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClassWorkoutExercisesType } from '../../types/classes.type';
import { ClassesServices } from '../../services/classes.service';
import { useSnackbar } from '../../hooks/snackbar.hook';
import { HttpResponse } from '../../services/base.service';
import { OptionType } from '../../components/AppAutocompleteField';
import { ExerciseType } from '../../types/exercise.type';
import { ExercisesServices } from '../../services/exercises.service';
import { ExercisePicker } from '../common/ExercisePicker';

export function CLassExercisesEdit() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const classId = params.get('class_id');
    const workoutId = params.get('workout_id');
    const { showSnackbar } = useSnackbar();

    const [classExercises, setClassExercises] = React.useState<ClassWorkoutExercisesType[]>([]);
    const [exercisesList, setExercisesList] = React.useState<OptionType[]>([]);

    React.useEffect(() => {
        ClassesServices.getClassExercises(Number(classId), Number(workoutId)).then((resp: HttpResponse<ClassWorkoutExercisesType[]>) => {
            setClassExercises(resp.data);
        }).catch((error) => {
            const msg = (error as any).response?.data?.message || 'An error occurred';
            console.error(msg, error);
            showSnackbar(msg, 'error');
        })

        ExercisesServices.getAll().then((resp: HttpResponse<ExerciseType[]>) => {
            setExercisesList(resp.data.map((e: ExerciseType) => {
                return {
                    id: e.id,
                    name: e.name,
                    video: e.video
                }
            }));
        })

    }, []);

    const handleBack = () => {
        navigate('/classes');
    }


    const handleExerciseChange = (exerciseId: number, stationNumber: number) => {
        //Call the API to update the exercise for the given station
        ClassesServices.updateClassExercise({
            class_id: parseInt(classId!),
            workout_id: parseInt(workoutId!),
            exercise_id: exerciseId,
            station_number: stationNumber,
        })
            .then(() => {
                //Update the local state
                // Update the local state to reflect the changes
                setClassExercises((prevExercises) =>
                    prevExercises.map((exercise) =>
                        exercise.station_number === stationNumber
                            ? { ...exercise, exercise_id: exerciseId, exercise: exercisesList.find((e) => e.id === exerciseId) }
                            : exercise
                    )
                );

                // Show notification
                showSnackbar('Exercise updated successfully!', 'success');
            })
            .catch((error) => {
                const msg = (error as any).response?.data?.message || 'Failed to update exercise';
                console.error(msg, error);
                showSnackbar(msg, 'error');
            });
    };

    return (
        <Container>
            <AppToolbar title={`Edit Class Exercises`}>
                <AppButton startIcon={<ArrowBack />} onClick={handleBack}>
                    Back
                </AppButton>
            </AppToolbar>
            <Container>
                {classExercises.map((classWorkoutExercise: ClassWorkoutExercisesType, index: number) => (
                    <ExercisePicker
                        key={`${index}.${classWorkoutExercise.id}`}
                        label={'Station ' + classWorkoutExercise.station_number}
                        onChange={(exerciseId: number) => {
                            if (exerciseId != undefined) {
                                handleExerciseChange(exerciseId, classWorkoutExercise.station_number!);
                            }
                        }}
                        options={exercisesList}
                        exercise={classWorkoutExercise.exercise!!}
                    />
                ))}
            </Container>
        </Container >
    );
};
