import React from 'react';
import { Container, Typography } from '@mui/material';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import { HttpResponse } from '../../services/base.service';
import { AppBox } from '../../components/AppBox';
import { AppFieldProps } from '../../types/components.type';
import { ExerciseType } from '../../types/exercise.type';
import { ExercisesServices } from '../../services/exercises.service';
import { ExercisePicker } from '../common/ExercisePicker';

export type WarmupExercisesFieldProps = {

} & AppFieldProps

export const WarmupExercisesField: React.FC<WarmupExercisesFieldProps> = (props: WarmupExercisesFieldProps) => {
    const { control, setValue } = props;

    const controlName = 'warmup_exercises';
    const [warmupExercises, setWarmumExercises] = React.useState<ExerciseType[]>([]);

    React.useEffect(() => {
        ExercisesServices.getWarmUpExercises().then((resp: HttpResponse<ExerciseType[]>) => {
            setWarmumExercises(resp.data);
        })
    }, []);

    const exercisesCount = useWatch({
        control,
        name: 'exercises_count',
        defaultValue: control._formValues['exercises_count'] || 0,
    });

    /**
     * Whatch to force a render after the follow useEffect set the random exercises for the warmup
     */
    useWatch({
        control,
        name: controlName,
        defaultValue: control._formValues[controlName] || [],
    });

    React.useEffect(() => {
        if (warmupExercises.length > 0) {
            control._formValues[controlName] = control._formValues[controlName] || [];

            // Initialize random exercises if they are not set
            const updatedValues = [...(control._formValues[controlName] || [])];
            for (let i = 0; i < exercisesCount; i++) {
                if (!updatedValues[i]) {
                    const randomExercise = getRandomExercise();
                    updatedValues[i] = {
                        exercise_id: randomExercise.id,
                        exercise: randomExercise,
                    };
                }
            }
            // Set the updated values in the form
            setValue?.(controlName, updatedValues);
        }
    }, [warmupExercises, exercisesCount]);

    // Generate a random exercise
    const getRandomExercise = (): ExerciseType => {
        const randomIndex = Math.floor(Math.random() * warmupExercises.length);
        return warmupExercises[randomIndex];
    };

    const handleExerciseChange = (index: number, exerciseId: number, field: any) => {
        const updatedValues = field.value ? [...field.value] : [];
        updatedValues[index] = {
            exercise_id: exerciseId,
            exercise: warmupExercises.find((e) => e.id === exerciseId)
        };
        // Notify React Hook Form of the change
        field.onChange(updatedValues);
    };

    return (
        <Controller
            name={controlName}
            control={control}
            rules={{
                validate: (value: any = []) => {
                    return value.length === parseInt(exercisesCount) || `All the warmup exercises must be defined!`;
                }
            }}
            render={({ field, fieldState }) => (
                <>
                    {fieldState.error && (
                        <AppBox>
                            <Typography color="error">
                                {fieldState.error.message}
                            </Typography>
                        </AppBox>
                    )}
                    <>
                        {Array.from({ length: exercisesCount }).map((_, index: number) => (
                            <ExercisePicker
                                key={index}
                                label={`Exercise ${index}`}
                                onChange={(exerciseId: number) => {
                                    handleExerciseChange(index, exerciseId, field);
                                }}
                                options={warmupExercises}
                                exercise={field.value && field.value[index] ? field.value[index].exercise : null}
                            />

                        ))}
                    </>
                </>
            )}
        />

    );
};



